































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'LessonInfo' })
export default class LessonInfo extends Vue {
  @Prop() private readonly title!: string;
  @Prop() private readonly subtitle!: string;
  @Prop() private readonly value!: string;
  @Prop() private readonly unit!: string;
  @Prop() private readonly color!: string;
  @Prop() private readonly icon!: string;
  @Prop() private readonly order!: number;

  get orderCard() {
    if (this.order === undefined) return 99;
    return this.order;
  }
}
