









































// todo: Сделать плашку, если чат недосступен для этого урока
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
// Types
import { IChatMessage } from '@/types/chat';
// Components
import Message from '@/components/chat/components/Message.vue';

@Component({ components: { Message } })
export default class ChatComponent extends Vue {
  @Ref('chatBody') private chatBodyElement!: HTMLDivElement;

  @Prop({ default: false }) readonly rewindable!: boolean;
  @Prop({ default: true }) readonly header!: boolean;
  @Prop({ required: true }) readonly messages!: IChatMessage[];
  @Prop({ required: true }) readonly currentTime!: number;

  public showScrollButton = false;
  public autoScrolling = true;
  public cursorOverChat = false;

  /**
   * Возвращает сообщения доступные к текущему времени
   */
  get showingMessages() {
    if (!this.messages) return;
    const messages = this.messages.filter((message) => message.time < this.currentTime);
    if (this.autoScrolling) this.scrollingToNewMessage();
    return messages;
  }

  mounted() {
    if (window) {
      this.chatBodyElement.addEventListener('mouseenter', () => { this.cursorOverChat = true; });
      this.chatBodyElement.addEventListener('mouseleave', () => { this.cursorOverChat = false; });
      this.chatBodyElement.addEventListener('scroll', this.toggleAutoScroll);
    }
  }

  beforeDestroy() {
    this.chatBodyElement.removeEventListener('scroll', this.toggleAutoScroll);
    this.chatBodyElement.removeEventListener('mouseenter', () => { this.cursorOverChat = true; });
    this.chatBodyElement.removeEventListener('mouseleave', () => { this.cursorOverChat = false; });
  }

  /**
   * Переключение автоскрола.
   * Отключается, когда пользователь скролит историю чата
   * @param {Event} event - событие при скролле чата
   * @returns
   */
  toggleAutoScroll() {
    if (this.cursorOverChat) {
      const topScrollHeight = Math.ceil(this.chatBodyElement.scrollTop);
      const chatHeight = this.chatBodyElement.scrollHeight;
      const lastMessageHeight = this.chatBodyElement.lastElementChild?.clientHeight || 0;

      // Определяем чата зону без автоскрола
      const heightScrollingDisabled = chatHeight - lastMessageHeight;

      if (topScrollHeight < heightScrollingDisabled) this.autoScrolling = false;
      else this.autoScrolling = true;
    }
  }

  scrollingToNewMessage() {
    if (this.chatBodyElement !== undefined) this.chatBodyElement.scrollTop = this.chatBodyElement.scrollHeight;
  }

  showScrollUpButton() {
    if (this.autoScrolling !== true) this.showScrollButton = true;
  }

  clickMessage(message: any) {
    if (!this.rewindable) return;
    const { from, to, time } = message;
    if (from !== undefined) this.$emit('rewind-to-interval', { from, to });
    if (time !== undefined) this.$emit('rewind-to-time', time);
  }
}
