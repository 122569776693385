







































































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { store } from '@/store';
import VideoPlayer from '@/components/VideoPlayer.vue';
import Chat from '@/components/chat/index.vue';
import FeedbackDrawer from '@/components/FeedbackDrawer.vue';
import VisualMetric from '@/components/visualization-metric/VisualMetric.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import TextMetric from '@/components/visualization-metric/TextMetric.vue';
import Comments from '@/components/comments/Comments.vue';
import { IVisualMetric } from '@/types/metric';
import { ICurrentTimes, ITimestamp } from '@/types/common';
import { IUser } from '@/types/user';
import { AUTH } from '@/services/auth';

@Component({
  components: {
    VideoPlayer,
    Chat,
    Comments,
    FeedbackDrawer,
    VisualMetric,
    UserAvatar,
    TextMetric,
  },
})
export default class LessonPage extends Vue {
  @Ref('video-player') videoPlayer!: any;

  public rollUpPlayer = false;
  public enteredText = '';
  public tab: null | number = null; // активная вкладка
  public currentTime = 0;
  public intervalsPlayer: Array<ITimestamp> = [];
  public intervalsPlayerTitle = '';
  public intervalSwitcher: number | null = null;

  /**
   * Для принудиельного ре-рендинга компонента. Фикс бага дублирования компонентов
   */
  public componentKey = 0;

  /**
   * Указанные секции будут развёрнуты по-умолчанию
   */
  accordeonSubsections = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  ];

  @Watch('tab')
  watchTab() {
    window.dispatchEvent(new Event('resize')); // Фикс бага с неотрисовкой графиков при переключении вкладок
  }

  get loadedMetrics() {
    return store.metrics.state.loaded;
  }

  get lesson() {
    return store.metrics.state.lesson;
  }

  get sectionTitles() {
    return store.metrics.state.sections;
  }

  get chatMessages() {
    return store.chat.state.messages;
  }

  get feedbackByLesson() {
    return store.feedback.state.commentsByLesson;
  }

  get renderListMetrics() {
    return store.metrics.state.renderListMetrics;
  }

  get textMetrics() {
    return store.metrics.state.textMetrics;
  }

  get videoDuration() {
    const { lesson } = store.metrics.state;
    if (lesson?.framesCount && lesson.fps) return lesson?.framesCount / lesson.fps;
    return null;
  }

  get currentTimeFromLocalStorage() {
    const user: IUser | null = AUTH.getUser();
    if (!user || !this.lesson) return 0;

    const data = localStorage.getItem('currentTimes');
    if (!data) return 0;

    const currentTimes: ICurrentTimes = JSON.parse(data);
    if (currentTimes[user.id] === undefined) return 0;
    if (currentTimes[user.id][this.lesson.id] === undefined) return 0;

    return currentTimes[user.id][this.lesson.id];
  }

  async mounted() {
    const lessonId = Number(this.$route.params.id);
    await store.chat.actions.fetchChatMessages(lessonId);
    await store.feedback.actions.fetchCommentsByLesson(lessonId);
    await store.metrics.actions.init(lessonId);
    this.componentKey = Math.random();

    this.scrollToMetric();
  }

  created() {
    window.addEventListener('scroll', this.videoPlayerScrollResize);
  }

  async beforeDestroy() {
    window.removeEventListener('scroll', this.videoPlayerScrollResize);
    await this.saveCurrentTime();
  }

  saveCurrentTime() {
    const user: IUser | null = AUTH.getUser();
    if (!user || !this.lesson) return;

    let data = localStorage.getItem('currentTimes');
    if (!data) data = '{}';

    const currentTimes: ICurrentTimes = JSON.parse(data);
    if (currentTimes[user.id] === undefined) currentTimes[user.id] = {};
    if (currentTimes[user.id][this.lesson.id] === undefined) currentTimes[user.id][this.lesson.id] = 0;

    currentTimes[user.id][this.lesson.id] = this.currentTime;
    localStorage.setItem('currentTimes', JSON.stringify(currentTimes));
  }

  setVideoTime(
    from: number,
    metric: IVisualMetric,
    to?: undefined | number,
  ): void {
    this.videoPlayer.setCurrentTime(from);
    this.videoPlayer.video.play();
    this.setPlayerIntervals(metric);
    this.intervalSwitcher = metric.id;
    if (to) {
      this.videoPlayer.startIntervalTime = from;
      this.videoPlayer.stopIntervalTime = to;
    }
  }

  /**
   * Изменяяет размер видеоплеера при прокрутке страницы
   */
  videoPlayerScrollResize(): void {
    if (window.scrollY > 0) this.rollUpPlayer = true;
    if (window.scrollY <= 0) this.rollUpPlayer = false;
  }

  scrollToMetric() {
    this.$nextTick(() => {
      const { section, subsection } = this.$route.params;
      this.tab = Number(section) - 1;
      const element = document.getElementById(`subsection-${subsection}`);
      if (element) {
        const top = element.getBoundingClientRect().top - 200;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    });
  }

  setPlayerIntervals(metric: IVisualMetric) {
    this.intervalsPlayer = store.player.state.intervals[metric.id];
    if (this.intervalsPlayer?.length) this.intervalsPlayerTitle = metric.title;
  }

  resetPlayerIntervals() {
    this.intervalsPlayer = [];
    this.intervalsPlayerTitle = '';
    this.intervalSwitcher = null;
  }

  showIntervalSwitcher(metricId: number) {
    return !!store.player.state.intervals[metricId];
  }

  share(event: any, { section, subsection }: {section: number, subsection: number}) {
    const page = this.$route.path.split('/')[1];
    const lessonId = this.$route.path.split('/')[2];
    const url = `${window.location.hostname}/${page}/${lessonId}/${section}/${subsection}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        const defaul = event.target.style;
        event.target.style.marginLeft = '150px';
        event.target.style.marginTop = '-30px';
        event.target.style.opacity = '0';
        setTimeout(() => {
          event.target.style = defaul;
          event.target.style.opacity = '0';
        }, 300);
        setTimeout(() => { event.target.style.opacity = '1'; }, 600);
      });
  }
}
