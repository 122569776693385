











import { Component, Vue, Prop } from 'vue-property-decorator';
import StatisticCard from '@/components/cards/StatisticCard.vue';
import { IStatisticCard } from '@/types/common';

@Component({
  name: 'LessonInfo',
  components: { StatisticCard },
})
export default class LessonInfo extends Vue {
  @Prop() private readonly data!: IStatisticCard;
}
