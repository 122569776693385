


















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFeedback, IFeedbackDto } from '@/types/feedback';
import Comment from '@/components/comments/Comment.vue';
import { API } from '@/services/api';

@Component({
  components: {
    Comment,
  },
})
export default class Comments extends Vue {
  /**
   * Имя графика к которому отображаем комментарии
   * Т.к. к одной метрике может быть множество графиков, для определения к
   * какому графику был оставлен комментарий
   */
  @Prop() private readonly chartName!: string;
  /**
   * Id метрики урока для коорой блок комментариев
   */
  @Prop() private readonly metricId!: number;
  /**
   * Id урока
   */
  @Prop() private readonly lessonId!: number;
  /**
   * Список всех комментариев для данного урока
   */
  @Prop() private readonly comments!: IFeedback[];
  /**
   * Введеный текст в форме добавления комментария
   */
  private enteredText = '';
  /**
   * Список новых добавленных комментариев пользователем для отображения
   */
  private newComments: Array<IFeedback|null> = [];

  /**
   * Возвращает из всех комментариев к уроку, комментарии к указанному графику
   */
  get oldComments() {
    return this.comments.filter(({ processedDataId }) => processedDataId === this.metricId);
  }

  get CountComments() {
    return this.oldComments.length + this.newComments.length;
  }

  /**
   * Правило, ограничивающее длину сообщения
   */
  private rulesBroken = false;
  get rules() {
    return [
      (v: any) => {
        if (v.length <= 255) {
          this.rulesBroken = false;
          return true;
        }
        this.rulesBroken = true;
        return '';
      },
    ];
  }
  /**
   * Отправка добавленного комментария
   */
  async sendFeedback() {
    const commentData: IFeedbackDto = {
      lessonId: this.lessonId,
      comment: this.enteredText,
      processedDataId: this.metricId,
    };
    try {
      const response = await API.feedback.create(commentData);
      if (response.data) {
        this.newComments.push(response.data);
        this.enteredText = '';
      }
    } catch (error: any) {
      this.$notify({ type: 'error', content: error });
    }
  }
}
