



































import { Component, Vue, Prop } from 'vue-property-decorator';
import UserAvatar from '@/components/UserAvatar.vue';

@Component({
  components: { UserAvatar },
})
export default class MessageList extends Vue {
  @Prop({
    required: true,
    type: Number,
  })
  time!: number;

  @Prop({
    required: true,
    type: String,
  })
  username!: string;

  @Prop({
    required: true,
    type: String,
  })
  message!: string;
}
