













import { Component, Vue, Prop } from 'vue-property-decorator';
import { visualizationComponent, metricValues, ITextMetric } from '@/types/metric';

@Component({ name: 'VisualMetric' })
export default class VisualMetric extends Vue {
  @Prop() public readonly id!: number;
  @Prop() public readonly visualization!: visualizationComponent;
  @Prop() public readonly videoDuration!: number | undefined;
  @Prop() public readonly value!: metricValues;
  @Prop({ required: false }) public readonly text?: ITextMetric;

  get getComponent() {
    if (this.visualization) return () => import(`@/components/visualization-metric/${this.visualization}.vue`);
    return null;
  }
}
