












































































































































































































































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { ITimestamp } from '@/types/common';

@Component
export default class videoPlayer extends Vue {
  @Ref('video') video!: HTMLVideoElement & { requestPictureInPicture: any };
  @Ref('player') player!: HTMLDivElement;
  @Ref('buffer') buffer!: HTMLDivElement;
  @Ref('intervals') intervalsDOM!: HTMLDivElement;
  @Ref('time-tooltip') timeTooltip!: HTMLDivElement;
  @Ref('time-progress') timeProgress!: HTMLDivElement;
  @Prop() protected readonly src!: string;
  @Prop() protected readonly poster!: string;
  @Prop() protected readonly startTime?: number;
  @Prop() protected readonly intervals?: Array<ITimestamp>;
  @Prop() protected readonly intervalsTitle?: string;

  public isPlay = false;
  public isPlayed = false;
  public volume = 50;
  public muted = false;
  public duration = 0;
  public currentTime = 0;
  public isPlayingBeforeHoldOnSlider = false;
  public holdOnSlider = false;
  public loading = true;
  public fullscreen = false;
  public zoomFace = false;
  public pictureInPicture = false;
  public showSpeedList = false;
  public speedList = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
  public speedIndx = 3;
  public arrowLeftPress = false;
  public arrowRightPress = false;
  public startIntervalTime: number | null = null;
  public stopIntervalTime: number | null = null;

  get supportPictureInPicture(): boolean {
    if (!('pictureInPictureEnabled' in document)) return false;
    return true;
  }

  get documentPip(): Element & any {
    return document;
  }

  get showIntervalsButtons(): boolean {
    return !!this.intervals?.length;
  }

  get currentIntervals(): Array<ITimestamp> | undefined {
    const intervals: Array<ITimestamp> = [];
    if (!this.intervals) return undefined;
    this.intervals.forEach((interval) => {
      if ((this.currentTime >= interval.from) && (this.currentTime <= (interval.to ?? interval.from + 1))) {
        intervals.push(interval);
      }
    });
    return intervals;
  }

  loadedmetadata(): void {
    if (this.video?.duration !== undefined) this.duration = this.video.duration;
  }

  async togglePlay() {
    if (this.loading) return;
    if (this.video?.paused || this.video?.ended) await this.video.play();
    else this.video.pause();
  }

  timeupdate(): void {
    if (this.loading) return;
    if (this.holdOnSlider) return;
    this.currentTime = this.video?.currentTime;
    this.$emit('timeupdate', this.currentTime);
    if (this.stopIntervalTime) {
      if (Math.floor(this.currentTime) === this.stopIntervalTime) {
        this.video.pause();
        this.stopIntervalTime = null;
      }
    }
  }

  setCurrentTime(time: number): void {
    if (this.loading) return;
    this.video.currentTime = time;
    this.currentTime = this.video.currentTime;

    if (this.stopIntervalTime) {
      if (
        time < Number(this.startIntervalTime)
        || time > Number(this.stopIntervalTime)
      ) {
        this.startIntervalTime = null;
        this.stopIntervalTime = null;
      }
    }
  }

  updateTimeTooltip(event: any): void {
    const coordinatesPlayer = this.timeProgress.getBoundingClientRect();
    const cursorX = event.clientX - coordinatesPlayer.left;
    let secondsFromStart = Math.round(
      (cursorX / coordinatesPlayer.width) * this.duration,
    );

    if (secondsFromStart < 0) secondsFromStart = 0;
    if (secondsFromStart > this.duration) secondsFromStart = this.duration;

    this.timeTooltip.textContent = `${this.$secondsToTime(secondsFromStart)}`;
    this.timeTooltip.style.left = `${cursorX}px`;
  }

  mousedownSlider(): void {
    this.isPlayingBeforeHoldOnSlider = this.isPlay;
    this.holdOnSlider = true;
    this.video.pause();
  }

  async mouseupSlider() {
    this.holdOnSlider = false;
    if (this.isPlayingBeforeHoldOnSlider) await this.video.play();
  }

  @Watch('volume')
  setVolume(val: number): void {
    if (this.loading) return;
    if (val !== 0 && this.muted) this.muted = false;
    if (val === 0) this.muted = true;
    this.video.volume = val / 100;
  }

  @Watch('muted')
  setMute(val: boolean): void {
    if (this.loading) return;
    this.video.muted = val;
    if (!val && !this.volume) this.volume = 34;
  }

  @Watch('speedIndx')
  setSpeed(val: number, oldVal: number): void {
    if (this.loading) return;
    if (val === undefined) {
      this.video.playbackRate = this.speedList[oldVal];
      this.speedIndx = oldVal;
    } else this.video.playbackRate = this.speedList[val];
    setTimeout(() => {
      this.showSpeedList = false;
    }, 200);
  }

  @Watch('loading')
  loadingWatch(val: boolean): void {
    if (!val && this.startTime) this.setCurrentTime(this.startTime);
  }

  @Watch('intervals')
  watchIntervals(val: Array<ITimestamp>) {
    if (val?.length) this.setIntervals();
    else this.resetIntervals();
  }

  toggleFullScreen() {
    if (this.loading) return;
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.fullscreen = false;
    } else {
      this.player.requestFullscreen();
      this.fullscreen = true;
    }
  }

  async togglePip() {
    if (this.loading) return;
    try {
      if (this.documentPip.pictureInPictureElement) {
        await this.documentPip.exitPictureInPicture();
      } else {
        await this.video.requestPictureInPicture();
      }
    } catch (error) {
      console.error(error);
    }
    this.pictureInPicture = !!this.documentPip.pictureInPictureElement;
  }

  bufferProgress() {
    // if (this.loading) return;
    // let innerColors = '';
    // let lastPercentEnd = 0;
    // for (let i = 0; i < this.video.buffered.length; i++) {
    //   const start = this.video.buffered.start(i);
    //   const end = this.video.buffered.end(i);
    //   const percentStart = Math.round((start * 100) / this.duration);
    //   const percentEnd = Math.round((end * 100) / this.duration);
    //   const divEmpty = `<div style="width: ${percentStart - lastPercentEnd}%;"></div>`;
    //   const divColor = `<div class="buffer-color" style="width: ${percentEnd - percentStart}%;"></div>`;
    //   lastPercentEnd = percentEnd;
    //   innerColors += `${divEmpty}${divColor}`;
    // }
    // this.buffer.innerHTML = innerColors;
  }

  keydown(e: any) {
    if (this.loading) return;
    const exclideDOMElements = ['textarea'];
    if (!exclideDOMElements.includes(e.target.localName)) {
      if (e.code === 'Space') {
        e.preventDefault();
        this.togglePlay();
      }

      if (e.code === 'ArrowRight') {
        this.setCurrentTime((this.currentTime += 5));
        this.arrowRightPress = true;
      }
      if (e.code === 'ArrowLeft') {
        this.setCurrentTime((this.currentTime -= 5));
        this.arrowLeftPress = true;
      }
    }
  }

  keyup(e: any) {
    const exclideDOMElements = ['textarea'];
    if (!exclideDOMElements.includes(e.target.localName)) {
      if (e.code === 'ArrowRight') this.arrowRightPress = false;
      if (e.code === 'ArrowLeft') this.arrowLeftPress = false;
    }
  }

  public setIntervals(): void {
    if (this.loading) return;
    if (!this.intervals?.length) return;
    let innerColors = '';
    let lastPercentEnd = 0;
    this.intervals.forEach((interval) => {
      const start = interval.from;
      let end = interval.to ?? start;
      if (end > this.duration) end = this.duration; // TODO убрать после фикса неккоректных данных

      const percentStart = (start * 100) / this.duration;
      let percentEnd = (end * 100) / this.duration;
      if ((percentEnd - percentStart) < 0.1) percentEnd = percentStart + 0.1;

      const divEmpty = `<div style="width: ${(percentStart - lastPercentEnd).toFixed(2)}%;"></div>`;
      const divColor = `<div class="interval-color" style="width: ${(percentEnd - percentStart).toFixed(2)}%; background: linear-gradient(0deg, ${interval.color}, transparent 80%);"></div>`;
      lastPercentEnd = percentEnd;
      innerColors += `${divEmpty}${divColor}`;
    });

    this.intervalsDOM.innerHTML = innerColors;
  }

  public resetIntervals(): void {
    this.intervalsDOM.innerHTML = '';
    this.startIntervalTime = null;
    this.stopIntervalTime = null;
  }

  nextInterval(): void {
    if (this.intervals) {
      const currentIndex = this.findCurrentInterval(this.intervals);
      const lastIndex = this.intervals.length - 1;
      const nextInretval = currentIndex === lastIndex
        ? this.intervals[0]
        : this.intervals[currentIndex + 1];
      this.setCurrentTime(nextInretval.from);
    }
  }

  prevInterval(): void {
    if (this.intervals) {
      const currentIndex = this.findCurrentInterval(this.intervals);
      const lastIndex = this.intervals.length - 1;
      const prevInretval = currentIndex === 0 || currentIndex === -1
        ? this.intervals[lastIndex]
        : this.intervals[currentIndex - 1];
      if (currentIndex !== 0 && this.intervals[currentIndex].from === this.intervals[currentIndex - 1].from) prevInretval.from -= 1;
      this.setCurrentTime(prevInretval.from);
    }
  }

  findCurrentInterval(intervals: Array<ITimestamp>): number {
    const index = intervals.findIndex(({ from }) => this.currentTime < from);
    return index === -1 ? intervals.length - 1 : index - 1;
  }

  mounted() {
    // setTimeout(() => this.bufferProgress(), 4000);
    document.addEventListener('keydown', this.keydown);
    document.addEventListener('keyup', this.keyup);
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
    document.removeEventListener('keyup', this.keyup);
  }
}
